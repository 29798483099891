.songs-list-container{
    padding-bottom: 250px;
    .song-item{
        margin: 0 auto 20px;
    }
    .empty-state{
        color: #ffffff;
        text-align: center;
        margin-top: 40px;
        font-size: 20px;
    }
}


.like-component{
    margin-left: 25px;
}
