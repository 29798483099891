.clubs-container{
    .clubs-list{
        margin-top: 20px;
        width: 100%;
        max-width: 400px;
        .empty-state{
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            font-weight: bold;
            color: #ffffff;
        }
    }
}

.add-club-form-container{
    width: 400px;
    max-width: 100%;
    .add-club-form{
        label, input{
            display: block;
            width: 100%;
            margin-bottom: 16px;
        }
        label{
            font-weight: bold;
            &.banner-label{
                text-align: center;
            }
        }
        .banner-input{
            z-index: -1;
            position: relative;
            padding: 0;
            top: -16px;
            height: 1px;
            margin-bottom: 0;
        }
        .preview-container{
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            label{
                width: 60px;
            }
            .close-icon{
                right: 0;
                top: 20px;
                transform: translateY(-50%);
            }
            .preview{
                max-height: 40px;
                margin-bottom: 16px;
                text-align: center;
                margin-left: 12px;
            }
        }
        
        .banner-area{
            height: 40px;
            border: 1px dashed #000000;
            margin-bottom: 16px;
            text-align: center;
            cursor: pointer;
            &.has-error{
                border-color: red;
                margin-bottom: 4px;
            }
            span{
                display: inline-block;
                margin-top: 10px;
            }
            &+span.error{
                font-size: 8px;
                color: red;
                line-height: 10px;
                margin-bottom: 16px;
                display: inline-block;
            }
        }
        .cta-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                min-width: 80px;
            }
        }
    }
}