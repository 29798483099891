.songs-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 30px;
    .input-container{
        width: 100%;
        display: flex;
        max-width: 100%;
        height: 36px;
        margin-bottom: 20px;
        justify-content: center;
        .icon{
            height: 100%;
            background:darkgrey;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 10px 10px 0;
        }
    }
    input {
        max-width: 400px;
        width: 100%;
        color: white;
        background-color: rgba(255, 255, 255, 0.1); 
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        backdrop-filter: blur(10px); 
    }
    
    .no-songs{
        margin-top: 20px;
        color: white;
        font-size: 20px;
        font-weight: bold;
    }
    .song-list{
        overflow: auto;
        scrollbar-width: thin; 
        &::-webkit-scrollbar {
            width: 0em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: white;
          outline: 1px solid black;
        }
        &:hover{
            &::-webkit-scrollbar {
                width: 0.1em;
            }
        }
    }
}