
.full-screen-loader {
  z-index: 9;
  height: 100vh;
  width: 100vw;
  position: fixed !important;
  top: 0;
  left: 0;
  font-size: 18px;  /* or any size you prefer */
  font-weight: bold;
  & > div {
    background: rgba(0, 0, 0, 0.5);

    ._loading_overlay_spinner {
      svg {
        circle {
          stroke: green;
          stroke-width: 7;
        }
      }
    }
  }
}

///////////////////// new loader spinner
// .full-screen-loader {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }

// .center {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .wave {
//   width: 5px;
//   height: 100px;
//   background: linear-gradient(45deg, cyan, #fff);
//   margin: 10px;
//   animation: wave 1s linear infinite;
//   border-radius: 20px;
// }
// .wave:nth-child(2) {
//   animation-delay: 0.1s;
// }
// .wave:nth-child(3) {
//   animation-delay: 0.2s;
// }
// .wave:nth-child(4) {
//   animation-delay: 0.3s;
// }
// .wave:nth-child(5) {
//   animation-delay: 0.4s;
// }
// .wave:nth-child(6) {
//   animation-delay: 0.5s;
// }
// .wave:nth-child(7) {
//   animation-delay: 0.6s;
// }
// .wave:nth-child(8) {
//   animation-delay: 0.7s;
// }
// .wave:nth-child(9) {
//   animation-delay: 0.8s;
// }
// .wave:nth-child(10) {
//   animation-delay: 0.9s;
// }

// @keyframes wave {
//   0% {
//     transform: scale(0);
//   }
//   50% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(0);
//   }
// }


