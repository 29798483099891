.checkbox-label{
    margin-bottom: 24px;
    input{
        margin-bottom: 0 !important;
        margin-right: 8px;
    }
    label{
        cursor: pointer;
    }
}

.register_main_button{
    background-color: #3e38e8;
}

.footer-container{
    max-width: 400px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    position: fixed;
    bottom: 0;

    .div-1{
        margin-top: 30px;
        flex: 2;
        text-align: right;

        .privacy-button{
            background: transparent;
            border: none !important; 
            font-size: 8px;
            margin-right: 35px;
        }
    }

    .div-2{
        flex: 2;
        .spotify-img{ 
            max-width: 150px;
            margin-top: 10px;
        }
    }

    .div-3{
        margin-top: 40px;
        flex: 2;
        .reserved-label{
            color: white;
            background: transparent;
            border: none !important;
            font-size: 8px;
        }
    }
    
}