.floating-action-button{
    position: fixed;
    bottom: 50px;
    background: #0770E6;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 10px;
    &.round{
        border-radius: 50%;
        padding: 0;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.position-left{
        left:20px;
    }
    &.position-right{
        right:20px;
    }
    &.position-center{
        left:50%;
        transform: translateX(-50%);
    }
}

@media screen and (min-width:800px) {
    .floating-action-button{
        &.position-left{
            left:calc(50% - 380px);
        }
        &.position-right{
            right:calc(50% - 380px);
        }
    }
}

@media screen and (max-height:600px) {
    .floating-action-button{
        display: none;
    }
}