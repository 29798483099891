.popup-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .popup{
        background-color: #ffffff;
        padding: 16px;
        border-radius: 10px;
        position: relative;
        max-width: 90%;
        .close-icon{
            display: flex;
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }
    }
    .popup-content{
        margin-top: 30px;
    }
}