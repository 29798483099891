.header{
    padding: 0 16px 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    position: relative;
    &.center{
        padding: 8px 0;
        justify-content: center;
        text-align: center;
    }
    .logo{
        flex: 1 0 50%;
        img{
            // top: 10px;
            // width: 250px;
            // height: 160px;

            width: 325px;
            height: 99px;
            position: relative;
            left: 0%;
            right: 75%;
            // top: 0%;
            bottom: 90.33%;
        }
    }
    .menu-icon{
        cursor: pointer;
    }
    .header-menu{
        position: fixed;
        right: -200px;
        top: 0;
        background: #19183c;
        height: 100%;
        width: 200px;
        z-index: 3;
        transition: right ease-out 0.5s;
        &.show{
            right: 0;
        }
        ul{
            padding: 0;
            margin: 0;
            margin-top: 60px;
            li{
                list-style: none;
                color: #ffffff;
                padding: 10px 16px;
                cursor: pointer;
            }
        }
        .close-icon{
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
}
.page-name{
    font-size: 26px;
    text-align: center;
    color: white;
    margin-top: 10px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    svg{
        margin:0 16px;
    }
    span{
        flex: 0 0 62px;
        &.content{
            flex: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    }
}