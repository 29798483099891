// .background {
//     background: radial-gradient(ellipse at center, rgba(127,0,173,0.6) 0%,
//       rgba(0,0,0,0.8) 60%, 
//       rgba(0,0,0,1) 90%), 
//       url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/31787/stars.jpg);
//     background-size: cover;
//     background-color: transparent;
//     z-index: -999;
//     color: white;
//     overflow: hidden;
//     font-family: 'Monoton', monospace;
//     font-size: 0.8em;
//   }
  

// h1 {
// 	animation: neon1 1.5s ease-in-out infinite alternate;
// }

// #overlay {
// 	position: absolute;
// 	right: 0;
// 	left: 0;
//     top: 90px;
// 	margin: auto;
// 	width: 100%;
// 	text-align: center;
// }

// #layer-0 {
// 	background: rgba(92,71,255,0);
// 	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
// 	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(92,71,255,0)), color-stop(25%, rgba(0,0,0,1)), color-stop(100%, rgba(255,71,255,1)));
//     background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
//     background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
//     background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
//     background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#ff47ff', GradientType=0 );
// 	height:400px;
// 	width:200vw;
// 	opacity:1;
// 	position:absolute;
// 	bottom:0;
// 	left:0;
// 	right: 0;
// 	margin: 0 -50%;
// 	overflow: hidden;
// 	transform: perspective(200px) rotateX(60deg);
// 	z-index: 0;
// }

// #layer-1 {
// 	background: rgba(92,71,255,1);
// 	background: -moz-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
// 	background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(92,71,255,1)), color-stop(50%, rgba(92,71,255,0)), color-stop(100%, rgba(92,71,255,0)));
// 	background: -webkit-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
// 	background: -o-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
// 	background: -ms-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
// 	background: linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#5c47ff', GradientType=1 );
// 	height: inherit;
// 	width: inherit;
// 	opacity:1;
// 	position:absolute;
// 	top:0;
// 	left:0;
// 	right: 0;
// 	margin: auto;
// 	// z-index: -5;
// }

// #layer-2 {
// 	background: rgba(92,71,255,0);
// 	background: -moz-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
// 	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(92,71,255,0)), color-stop(50%, rgba(92,71,255,0)), color-stop(100%, rgba(92,71,255,1)));
// 	background: -webkit-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
// 	background: -o-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
// 	background: -ms-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
// 	background: linear-gradient(135deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#5c47ff', GradientType=1 );
// 	height: inherit;
// 	width: inherit;
// 	opacity:1;
// 	position:absolute;
// 	top:0;
// 	left:0;
// 	right: 0;
// 	margin: auto;
// 	// z-index: -5;
// }

// #layer-corner {
// 	background: rgba(71,255,203,1);
// 	background: -moz-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
// 	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(71,255,203,1)), color-stop(54%, rgba(96,130,223,0)), color-stop(100%, rgba(117,24,240,0)));
// 	background: -webkit-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
// 	background: -o-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
// 	background: -ms-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
// 	background: linear-gradient(to bottom, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffcb', endColorstr='#7518f0', GradientType=0 );
// 	height: inherit;
// 	width: inherit;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	margin: auto;
// 	// z-index: -5;
// 	animation: layercorneranim 2.5s ease-in-out infinite alternate;	
// }

// #hey {
// 	width: 100%;
// 	background-color: #011;
// 	position: absolute;
// 	bottom: 253px; right: 0; left: 0;
// 	margin: auto;
// 	// z-index: -10;
// 	transform: perspective(200px);
// }

// #layer-up {
// 	background: rgba(71,255,203,0);
// 	background: -moz-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
// 	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(71,255,203,0)), color-stop(50%, rgba(71,255,203,0)), color-stop(100%, rgba(71,255,203,1)));
// 	background: -webkit-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 54%, rgba(71,255,203,1) 100%);
// 	background: -o-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
// 	background: -ms-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
// 	background: linear-gradient(to bottom, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffcb', endColorstr='#47ffcb', GradientType=0 );
// 	height:300px;
// 	width: inherit;
// 	opacity: 1;
// 	position: absolute;
// 	bottom:0; left:0; right: 0; margin: auto;
// 	overflow: hidden;
// 	// z-index: -5;
// 	animation: layerupanim 2.5s ease-in-out infinite alternate;	
// }

// #lines {
//     background-size: 40px 40px;    
//     background-image: repeating-linear-gradient(0deg, #60DCD3, #60DCD3 2px, transparent 1px, transparent 40px),repeating-linear-gradient(-90deg, #60DCD3, #60DCD3 2px, transparent 2px, transparent 40px);
//     height:400px;
//     width:100%;
//     opacity:1;
//     position:absolute;
//     top:0;
//     left:0;
//     // z-index:-4;
// }

// #mtn {
// 	background-color: purple;
// 	height:300px;
// 	width:1200px;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	margin: auto;
// 	// z-index: -8;
// 	transform: perspective(600px);
// }

// @keyframes neon1 {
//   from {
//     text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF11A7, 0 0 70px #FF11A7, 0 0 80px #FF11A7, 0 0 100px #FF11A7, 0 0 150px #FF1177;
//   }
//   to {
//     text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF11A7, 0 0 35px #FF11A7, 0 0 40px #FF11A7, 0 0 50px #FF11A7, 0 0 75px #FF11A7;
//   }
// }

// @keyframes layerupanim
// {
// 	from {
// 		height: 140px;
// 	}
// 	to {
// 		height: 155px;
// 	}
// }

// @keyframes layercorneranim
// {
// 	from {
// 		height: 400px;
// 	}
// 	to {
// 		height: 540px;
// 	}
// }


















































// h1 {
// 	animation: neon1 1.5s ease-in-out infinite alternate;
// }

// #overlay {
// 	position: absolute;
// 	right: 0;
// 	left: 0;
//     top: 90px;
// 	margin: auto;
// 	width: 100%;
// 	text-align: center;
//       z-index:-5;
//       color: white;
//       overflow: hidden;
//       font-family: 'Monoton', monospace;
//       font-size: .85em;
// }



// @keyframes neon1 {
//     from {
//       text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF00FF, 0 0 70px #FF00FF, 0 0 80px #FF00FF, 0 0 100px #FF00FF, 0 0 150px #FF007F;
//     }
//     to {
//       text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF00FF, 0 0 35px #FF00FF, 0 0 40px #FF00FF, 0 0 50px #FF00FF, 0 0 75px #FF007F;
//     }
//   }
  


// @keyframes layerupanim
// {
// 	from {
// 		height: 140px;
// 	}
// 	to {
// 		height: 155px;
// 	}
// }

// @keyframes layercorneranim
// {
// 	from {
// 		height: 400px;
// 	}
// 	to {
// 		height: 540px;
// 	}
// }





.login-container{
    padding-top: 20px;
    // text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 5;
    .signin-form{
        display: flex;
        flex-flow: column;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        z-index: 5;
        input{
            margin-bottom: 24px;
            z-index: 5;
            // background-color: black;
        }
        label{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            color: white;
            text-align: left;
            z-index: 5;
        }
    }
    .or{
        margin: 24px auto;
        font-size: 20px;
        color: white;
        font-weight: bold;
        text-align: center;
        z-index: 5;
    }
    .register-line{
        margin-top: 20px;
        color: white;
        font-size: 20px;
        z-index: 5;
        span{
            font-weight: bold;
            color:#07E667 ;
            // color: magenta;
            cursor: pointer;
            z-index: 5;
        }
    }
    .login-line{
        margin-top: 20px;
        color: white;
        font-size: 20px;
        span{
            font-weight: bold;
            color: #07E667;
            cursor: pointer;
            z-index: 5;
        }
    }
    .signin-button{
        background-color: #3e38e8;
        font-size: 16px;
        z-index: 5;
    }
    .google-signup-button{
      font-size: 16px;
      max-width: 400px;
      width: 100%;
      background-color: #3e38e8;
      z-index: 5;
      opacity: 0;
      position: absolute;
      bottom: 70px;
  }
    .continue-without-button{
        //background-color: #4701a3;
        //z-index: 5;
        font-family: 'Arial', sans-serif;  /* Choose a readable font family */
        font-size: 32px;  /* Increase font size for better readability */
        font-weight: bold;  /* Make the text bold */
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 13rem;
        overflow: hidden;
        height: 3rem;
        background-size: 300% 300%;
        backdrop-filter: blur(1rem);
        border-radius: 5rem;
        transition: 0.5s;
        animation: gradient_301 5s ease infinite;
        border: double 4px transparent;
        //background-image: linear-gradient(#212121, #212121),  linear-gradient(137.48deg, #ffdb3b 10%,#FE53BB 45%, #8F51EA 67%, #0044ff 87%);
        background-image: linear-gradient(#212121, #212121),  linear-gradient(137.48deg, orange 15%,purple 45%, blue 75%);
        background-origin: border-box;
        background-clip: content-box, border-box;  
        position: absolute;
        top: 22%;
        width: 15rem;  /* was 13rem */
        height: 24rem;  /* was 3rem */
        line-height: 2; /* Double the line height */
        z-index: 6;
    }
    #container-stars {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: 0.5s;
        backdrop-filter: blur(1rem);
        border-radius: 5rem;
      }
      
      strong {
        z-index: 2;
        //font-family: 'Avalors Personal Use';
        //font-size: 12px;
        //letter-spacing: 5px;
        //color: #FFFFFF;
        text-shadow: 0 0 4px white;
      }
      
      #glow {
        position: absolute;
        display: flex;
        width: 12rem;
      }
      
      .circle {
        //width: 100%;
        //height: 30px;
        //filter: blur(2rem);
        //animation: pulse_3011 4s infinite;
        z-index: -1;
      }
      
      .circle:nth-of-type(1) {
        background: rgba(254, 83, 186, 0.636);
        //background: red;

      }
      
      .circle:nth-of-type(2) {
        background: rgba(142, 81, 234, 0.704);
        //background: red;
      }
      
      .btn:hover #container-stars {
        z-index: 1;
        background-color: #212121;
      }
      
      .btn:hover {
        transform: scale(1.1)
      }
      
      .btn:active {
        border: double 4px #FE53BB;
        //border: double 4px blue;
        background-origin: border-box;
        background-clip: content-box, border-box;
        animation: none;
      }
      
      .btn:active .circle {
        background: #FE53BB;
        //background: blue;
      }
      
      #stars {
        position: relative;
        background: transparent;
        width: 200rem;
        height: 200rem;
      }
      
      #stars::after {
        content: "";
        position: absolute;
        top: -10rem;
        left: -100rem;
        width: 100%;
        height: 100%;
        animation: animStarRotate 80s linear infinite;
      }
      
      #stars::after {
        background-image: radial-gradient(magenta 1px, transparent 1%);
        background-size: 50px 50px;
      }
      
      #stars::before {
        content: "";
        position: absolute;
        top: 0;
        left: -50%;
        width: 170%;
        height: 500%;
        animation: animStar 60s linear infinite;
      }
      
      #stars::before {
        //background-image: radial-gradient(#ffffff 1px, transparent 1%);
        background-image: radial-gradient(magenta 1px, transparent 1%);
        background-size: 50px 50px;
        opacity: 0.5;
      }
      
      @keyframes animStar {
        from {
          transform: translateY(0);
        }
      
        to {
          transform: translateY(-135rem);
        }
      }
      
      @keyframes animStarRotate {
        from {
          transform: rotate(360deg);
        }
      
        to {
          transform: rotate(0);
        }
      }
      
      @keyframes gradient_301 {
        0% {
          background-position: 0% 50%;
        }
      
        50% {
          background-position: 100% 50%;
        }
      
        100% {
          background-position: 0% 50%;
        }
      }
      
      @keyframes pulse_3011 {
        0% {
          transform: scale(0.75);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }
      
        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }
      
        100% {
          transform: scale(0.75);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }
}

.input-group {
    position: relative;
   }
   
   .input {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1);
    width:100%;
   }
   
   .user-label {
    position: absolute;
    left: 15px;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
   }
   
   .input:focus, .input:valid {
    outline: none;
    border: 1.5px solid #1a73e8;
   }
   
   .input:focus ~ label, .input:valid ~ label {
    transform: translateY(-50%) scale(0.8);
    background-color: #282928;
    
    border-radius: 0.3rem;
    padding: 0 .5em;
    color: #2196f3;

}

.footer-container{
    max-width: 400px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    z-index: 4;

    .div-1{
        margin-top: 30px;
        flex: 2;
        text-align: right;

        .privacy-button{
            background: transparent;
            border: none !important; 
            font-size: 8px;
            margin-right: 35px;
        }
    }

    .div-2{
        flex: 2;
        .spotify-img{ 
            max-width: 150px;
            margin-top: 10px;
        }
    }

    .div-3{
        margin-top: 40px;
        flex: 2;
        .reserved-label{
            color: white;
            background: transparent;
            border: none !important;
            font-size: 8px;
        }
    }
    
}


