.club-item{
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 10px 16px;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .club-item-overlay{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        opacity: 0.5;
        border-radius: 12px;
        z-index: 0;
    }
    .club-info{
        flex: 1 1 calc(100% - 30px);
        z-index: 1;
        .club-info-item{
            margin-bottom: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            label{
                font-weight: bold;
                margin-right: 8px;
            }
            input{
                width: 100%;
            }
            .club-info-value{
                padding: 8px 12px;
                font-size: 14px;
                line-height: 20px;
                &.preview{
                    max-height: 40px;
                }
            }
        }
        .club-songs-page{
            display: flex;
            justify-content: center;
            align-items: center;
            color: red;
            cursor: pointer;
        }
        .club-info-ctas{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .banner-input{
            z-index: -1;
            position: relative;
            padding: 0;
            top: -16px;
            height: 1px;
            margin-bottom: 0;
            visibility: hidden;
            &+span.error{
                font-size: 8px;
                color: red;
                line-height: 10px;
                margin-bottom: 16px;
                display: inline-block;
                position: relative;
                top: -16px;
            }
        }
        .preview-container{
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-bottom: 16px;
            label{
                width: 60px;
                font-weight: bold;
                margin-right: 8px;
            }
            .close-icon{
                position: absolute;
                right: 0;
                top: 20px;
                transform: translateY(-50%);
                cursor: pointer;
            }
            .preview{
                max-height: 40px;
                
                text-align: center;
                margin-left: 12px;
            }
        }
        
        .banner-area{
            height: 40px;
            border: 1px dashed #000000;
            text-align: center;
            cursor: pointer;
            display: block;
            background-color: #ffffff;
            &.has-error{
                border-color: red;
                margin-bottom: 4px;
            }
            span{
                display: inline-block;
                margin-top: 10px;
            }
            
        }
    }  
    .icons{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
    }
    
}

.deletion-content{
    p{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
    }
    .ctas{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
            min-width: 70px;
        }
        
    }
}