.clubs-container{
    .select-search__options{
        padding-left: 0;
        .select-search__option{
            color: #2FCC8B;
            &.is-selected{
                color: #ffffff;
            }
        }
    }
    display: flex;
    align-items: center;
    flex-flow: column;
    padding-top: 30px;
    .page-header{
        font-size: 24px;
        color: white;
        margin-bottom: 32px;
    }
    .select-button{
        width: 100%;
        max-width: 400px;
        margin-top: 30px;
        background-color: #3e38e8;
        font-size: 16px;
    }
    .select-search{
        width: 400px;
        max-width: 100%;
        input{
            background-color: #220674;
            color: white;
            &::placeholder {
                color: #ffffff;
                opacity: 1; 
              }
              
              &:-ms-input-placeholder { 
                color: #ffffff;
              }
              
              &::-ms-input-placeholder {
                color: #ffffff;
              }
        }
    }
}