

//todo: add animation
.snackbar-container {
  position: fixed;
  bottom: 76px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .snackbar {
    width: 90%;
    max-width: 400px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    font-size: 12px;
    font-weight: 400;
    color: white;
    &.error {
      background: red;
      opacity: 1;
    }
    &.success {
      background: green;
    }
    &.info {
      background-color: gray;
      color: darkgray;
    }
    svg {
      margin-right: 1.2rem;
      vertical-align: middle;
    }
  }
}
